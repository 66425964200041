import { Flex, HTMLChakraProps } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import LogoWord from '@/assets/icons/ic-logo.svg';

import { UserMenu } from '@/components/ui/shared/headers/CoreHeader/components/UserMenu';
import { CollectionsMenuDropdown } from '@/components/ui/shared/headers/HubsHeader/components/CollectionsMenuDropdown';
import { OriginalsMenuDropdown } from '@/components/ui/shared/headers/HubsHeader/components/OriginalsMenuDropdown';
import { SubscribePopup } from '@/components/ui/shared/headers/HubsHeader/components/SubscribePopup';
import { InstitutionLabel } from '@/components/ui/shared/InstitutionLabel/InstitutionLabel';
import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import {
  CoreNavigation,
  CoreNavigationActions,
  CoreNavigationBrand,
  CoreNavigationClose,
  CoreNavigationHamburger,
  CoreNavigationNav,
  ICoreNavigationProps,
} from '@/components/ui/shared/navigations/CoreNavigation/CoreNavigation';
import { AccessFrom } from '@/enums/AccessFrom';
import { useClientWindowScroll } from '@/hooks/useClientWindowScroll';

export interface ICoreHeaderProps extends HTMLChakraProps<'div'> {
  variant?: ICoreNavigationProps['variant'];
  onLogout: () => void;
}

export const HubsHeader: FC<ICoreHeaderProps> = ({ onLogout, variant = 'ghost', ...rest }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const scrollOffset = useClientWindowScroll();

  const isHomePage = router.route === '/hubs-subdomain/[slug]';
  const shouldShowNavbar = !isHomePage || (isHomePage && scrollOffset.y > 320);

  return (
    <CoreNavigation variant={isHomePage ? 'ghost' : 'default'} {...rest}>
      <CoreNavigationHamburger title={t('action.openMenu')} aria-label={t('action.openMenu')} />

      <CoreNavigationBrand w={{ base: 'auto', lg: 'full' }}>
        <NextLink href="/" passHref>
          <LogoWord css={{ height: '1.25rem' }} />
        </NextLink>
        <InstitutionLabel />
      </CoreNavigationBrand>

      <CoreNavigationNav>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          mt={{ base: 5, lg: 0 }}
          alignItems={{ base: 'flex-start', lg: 'baseline' }}
          visibility={{ lg: shouldShowNavbar ? 'visible' : 'hidden' }}
        >
          <CoreNavigationClose title={t('action.closeMenu')} aria-label={t('action.closeMenu')} />
          <Fragment>
            <CollectionsMenuDropdown />
            <OriginalsMenuDropdown />
            <NextLink href="/lectures" passHref legacyBehavior>
              <NavLink isActiveLink={router.asPath.startsWith('/lectures')}>
                {t('hubsNavigation.conferenceTalks')}
              </NavLink>
            </NextLink>
            <NextLink href="/conferences" passHref legacyBehavior>
              <NavLink isActiveLink={router.asPath.startsWith('/conferences')}>
                {t('hubsNavigation.conferences')}
              </NavLink>
            </NextLink>
            <NextLink href="/speakers" passHref legacyBehavior>
              <NavLink isActiveLink={router.asPath.startsWith('/speakers')}>{t('hubsNavigation.speakers')}</NavLink>
            </NextLink>
            <NextLink href="/societies" passHref legacyBehavior>
              <NavLink isActiveLink={router.asPath.startsWith('/societies')}>{t('hubsNavigation.societies')}</NavLink>
            </NextLink>
          </Fragment>

          <SubscribePopup />
        </Flex>
        <CoreNavigationActions>
          <UserMenu accessFrom={AccessFrom.Hubs} onLogout={onLogout} />
        </CoreNavigationActions>
      </CoreNavigationNav>
    </CoreNavigation>
  );
};
