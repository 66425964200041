/* eslint-disable */
import { useEffect, useState } from 'react';
import { useRafState } from 'react-use';
import { debounce } from 'lodash';

import { Router } from 'next/router';
import { isServer } from '@/utils/env';

export interface State {
  x: number;
  y: number;
}

export const useClientWindowScroll = (): State => {
  const [didRender, setDidRender] = useState(false);

  const [state, setState] = useRafState<State>({
    x: !isServer ? window.scrollX : 0,
    y: !isServer ? window.scrollY : 0,
  });

  useEffect(() => {
    const handleScroll = () => {
      setState({
        x: window.scrollX,
        y: window.scrollY,
      });
    };

    const debounceScroll = debounce(handleScroll, 50);

    window.addEventListener('scroll', debounceScroll, {
      capture: false,
      passive: true,
    });

    Router.events.on('routeChangeComplete', handleScroll);

    setDidRender(true);
    return () => {
      window.removeEventListener('scroll', debounceScroll);
      Router.events.off('routeChangeComplete', handleScroll);
    };
  }, []);

  return state;
};
