import { useDisclosure } from '@chakra-ui/hooks';
import { Button } from '@chakra-ui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { LibrariansContactForm } from '@/components/ui/features/librarians/LibrariansContactForm/LibrariansContactForm';

export const SubscribePopup = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      <Button onClick={onOpen} ml={{ base: 4, md: 9, lg: 4 }} size="md">
        {t('hubsNavigation.subscribe')}
      </Button>
      <LibrariansContactForm isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
};
