import { useResource, useResourceRelationship, useResources } from 'datx-jsonapi-react';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { OriginalsMenuDropdownDesktop } from '@/components/ui/shared/headers/OriginalsMenuDropdown/OriginalsMenuDropdown.desktop';
import { OriginalsMenuDropdownMobile } from '@/components/ui/shared/headers/OriginalsMenuDropdown/OriginalsMenuDropdown.mobile';
import { useIsClientSide } from '@/hooks/useIsClientSide';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { getHubBySlug, getHubsSubtree } from '@/queries/hubs';
import { queryAllOriginalsSeries } from '@/queries/originals-series';
import { getSubdomain } from '@/utils/subdomain-matcher';

export const OriginalsMenuDropdown: FC = () => {
  const { t } = useTranslation();
  const isClientSide = useIsClientSide();

  const host = isClientSide ? window.location.host : '';
  const subdomain = getSubdomain(host);

  const { slug } = useQueryParams<{ slug: string }>({
    slug: StringParam,
  });

  const { data: hub } = useResource(getHubBySlug(slug || subdomain));
  const { data: subtreeData } = useResourceRelationship(getHubsSubtree(hub?.id));
  const { data } = useResources(queryAllOriginalsSeries({ subject_id: subtreeData?.subtreeIds }));

  if (data?.length === 0) return null;
  return (
    <Fragment>
      <OriginalsMenuDropdownDesktop navigationTitle={t('hubsNavigation.originals')} navigationItems={data} />
      <OriginalsMenuDropdownMobile navigationTitle={t('hubsNavigation.originals')} navigationItems={data} />
    </Fragment>
  );
};
