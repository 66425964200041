export function getSubdomain(host: string) {
  const regex = getSubdomainMatchingRegexPattern();

  const hasSubdomain = host.match(regex);

  if (!hasSubdomain) return null;
  return hasSubdomain[1];
}

function getSubdomainMatchingRegexPattern() {
  const subdomainDepth = process.env.NEXT_PUBLIC_BASE_URL.split('.').length;

  if (subdomainDepth === 2) {
    return '^(?<publisherName>[^.]+?)([.].+){2}$';
  } else if (subdomainDepth === 3) {
    return '^(?<publisherName>[^.]+?)([.].+){3}$';
  }
  return '';
}
