import { useResource, useResourceRelationship, useResources } from 'datx-jsonapi-react';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { CollectionsMenuDropdownDesktop } from '@/components/ui/shared/headers/CollectionsMenuDropdown/CollectionsMenuDropdown.desktop';
import { CollectionsMenuDropdownMobile } from '@/components/ui/shared/headers/CollectionsMenuDropdown/CollectionsMenuDropdown.mobile';
import { useIsClientSide } from '@/hooks/useIsClientSide';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { getHubBySlug, getHubsSubtree } from '@/queries/hubs';
import { queryAllOriginalsSeries } from '@/queries/originals-series';
import { getSubdomain } from '@/utils/subdomain-matcher';

export const CollectionsMenuDropdown: FC = () => {
  const { t } = useTranslation();
  const isClientSide = useIsClientSide();

  const host = isClientSide ? window.location.host : '';
  const subdomain = getSubdomain(host);

  const { slug } = useQueryParams<{ slug: string }>({
    slug: StringParam,
  });

  const { data: hub } = useResource(getHubBySlug(slug || subdomain));
  const { data: subtreeData } = useResourceRelationship(getHubsSubtree(hub?.id));
  const { data } = useResources(queryAllOriginalsSeries({ subject_id: subtreeData?.subtreeIds, collection: 'true' }));

  if (data?.length === 0) return null;
  return (
    <Fragment>
      <CollectionsMenuDropdownDesktop navigationTitle={t('hubsNavigation.collections')} navigationItems={data} />
      <CollectionsMenuDropdownMobile navigationTitle={t('hubsNavigation.collections')} navigationItems={data} />
    </Fragment>
  );
};
