import { useState, useEffect } from 'react';

/**
 * Custom hook to use for two-pass rendering.
 *
 * More information: https://reactjs.org/docs/react-dom.html#hydrate
 */

export const useIsClientSide = () => {
  const [isClientSide, setIsClientSide] = useState(false);

  useEffect(() => {
    setIsClientSide(true);
  }, []);

  return isClientSide;
};
